import {
  LearningAreaAllocatedResponse,
  SubjectCoverageResponse,
} from '../user';
import { stringToEnum } from '../strToEnum';
import { KurasaPlanSlugType } from '../plans';
import { Curriculum } from '../tiny';

export type WorkspaceDetailResponse = {
  user_id: string;
  workspace_id: string;
  workspace_name: string;
  plan: KurasaPlanSlugType;
  plan_id: number;
  subscription_id: string;
  subscription_slug: string;
  curriculum: number;
  curriculum_slug: Curriculum;
  curriculum_abbreviation: string;
  menu: WorkspaceMenuInfo[];
  workspace_code: string | null;
  // Describes timestamp when the workspace license will expire
  expires_at: string;
  subscription_status: WorkspaceLicenseStatus;
};

export type WorkspaceLicenseStatus =
  | 'active'
  | 'expired'
  | 'expiring'
  | 'suspended';

export interface CreateWorkspaceRequest {
  name: string;
  user: string;
  classrooms: Array<{ name: string; level: number }>;
  invites: Array<{
    email: string;
    role: string;
  }>;
  curriculum: number;
}

export type WorkspaceMenuInfo = {
  role_id: string;
  role: UserRoleType;
  status: boolean;
  data: WorkspaceMenuItem[];
};

export type WorkspaceMenuItem = {
  id: string;
  name: string;
  url: string;
};

export interface InviteMultipleUsersRequest {
  workspace: string;
  invites: Array<{ email: string; role: string }>;
}

export interface InviteUserRequest {
  email: string;
  role: string;
  workspace: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
}

export interface WorkspaceUserResponse {
  user_id: string;
  first_name: string;
  last_name: string;
  username: string;
  roles: string;
}

export interface WorkspaceUser {
  user_id: string;
  first_name: string;
  last_name: string;
  username: string;
  roles: Array<{ role_id: string; role_name: UserRoleType }>;
}

export interface WorkspaceUserDetailsResponse {
  user: {
    id: string;
    fullname: string;
    email: string;
    phone: string;
    roles: Array<{ id: string; name: UserRoleType }>;
  };
  learningAreasAssigned: Array<LearningAreaAllocatedResponse>;
  syllabus_coverage: Array<SubjectCoverageResponse>;
}

export interface WorkspaceUserInvite {
  email: string;
  invites: Array<{ role: string; invited_at: string; status: string }>;
}

export interface WorkspaceTeacher {
  id: string;
  email: string;
  name: string;
}

export interface LessonPlanInfo {
  cohort: string;
  cohort_id: string;
  lesson_plans_done: number;
}

const kurasaRoles = [
  'admin',
  'owner',
  'class_teacher',
  'co_owner',
  'director',
  'introspector',
  'parent',
  'section_head',
  'head_teacher',
  'teacher',
  'subject_teacher',
] as const;

export type UserRoleType = (typeof kurasaRoles)[number];

export const UserRoleEnum = stringToEnum([...kurasaRoles]);

export interface WorkspaceScoreLabel {
  id: number;
  subject_id: number;
  subject_title: string;
  label: string;
  workspace_id: string;
  created_at: string;
  updated_at: string;
}

export interface ScoreLabel {
  id: number;
  subject_id: number;
  subject_title: string;
  label: string;
  workspace_id: string;
  created_at: string;
  updated_at: string;
}

export interface WorkspaceStatsResponse {
  total_classrooms: number;
  total_students: number;
  total_users: number;
}

export interface GetWorkspaceDetailsResponse {
  id: string;
  name: string;
  created_at: string;
  logo: string | null;
  owners: Array<{ id: string; first_name: string; last_name: string }> | null;
}

export interface GradingSystemBounds {
  lower_bound: number;
  upper_bound: number;
}

export type GradingSystemResponse = CBCGradingSystem | CCPGradingSystem;

export type UpdateGradingSystemRequest = CBCGradingSystem | CCPGradingSystem;

export interface CBCGradingSystem {
  below: GradingSystemBounds;
  approaching: GradingSystemBounds;
  meets: GradingSystemBounds;
  exceeds: GradingSystemBounds;
}

export interface CCPGradingSystem {
  highly_proficient: GradingSystemBounds;
  proficient: GradingSystemBounds;
  approaching_proficiency: GradingSystemBounds;
  developing: GradingSystemBounds;
  emerging: GradingSystemBounds;
}

export interface WorkspacePreferencesResponse {
  grading_system?: GradingSystemResponse;
  report_templates: Array<string>;
  assessment_weight?: {
    formative_weight: number;
    summative_weight: number;
  };
  next_term_opening_date?: string;
  current_term_closing_date?: string;
  current_term_opening_date?: string;
  summative_marklist: WorkspaceSummativePreferencesResponse;
}

export interface WorkspaceSummativePreferencesResponse {
  // Whether to show the level marklist ranking
  level_marklist_ranking: boolean;
  // Whether to show the level marklist total score
  level_marklist_total_score: boolean;
  // Whether to show the cohort marklist ranking
  cohort_marklist_ranking: boolean;
  // Whether to show the cohort marklist total score
  cohort_marklist_total_score: boolean;
  // Whether to show the student marklist ranking in student profile
  student_marklist_ranking: boolean;
  // Whether to show the student total score in student profile
  student_marklist_total_score: boolean;

  show_rubric_scores: boolean;
  show_percentage_scores: boolean;
  show_raw_scores: boolean;
}

export type UpdateWorkspacePreferencesRequest =
  WorkspaceSummativePreferencesResponse;

export interface UpdateTermDates {
  next_term_opening_date: string;
  current_term_closing_date: string;
  current_term_opening_date: string;
}
