import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    title: 'Kurasa Community',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'lesson-plans',
    children: [
      {
        path: 'create',
        title: 'Lesson Planning - Kurasa Community',
        children: [
          {
            path: 'custom',
            title: 'Custom Lesson Planning - Kurasa Community',
            loadComponent: () =>
              import(
                './pages/lesson-plans/feature/create/custom/create-custom-lesson-plan-form.component'
              ).then((m) => m.CreateCustomLessonPlanFormComponent),
          },
          {
            path: 'oup',
            title: 'Oxford University Press Lesson Planning - Kurasa Community',
            loadComponent: () =>
              import(
                './pages/lesson-plans/feature/create/oup/create-oup-lesson-plan-form.component'
              ).then((m) => m.CreateOupLessonPlanFormComponent),
          },
        ],
      },
    ],
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found.component').then((m) => m.NotFoundComponent),
  },
];
