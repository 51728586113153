import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';

import { environment } from '@kurasa-monorepo/environments';
import {
  DateRange,
  MapErrorService,
  RestClientService,
} from '@kurasa-monorepo/utils';
import {
  AILessonPlan,
  CreateCBCLessonPlanRequest,
  CreateCCPLessonPlanRequest,
  CreateIGCSELessonPlanRequest,
  LessonPlanRemarkRequest,
  LearningAreaAllocatedResponse,
  LessonPlanDetailsResponse,
  LessonPlanRemarksResponse,
  LessonPlanFromSchemeResponse,
  LessonPlanItemResponse,
  PaginationRequest,
  PublisherLessonPlanResponse,
  ReceivedLessonPlanResponse,
  ResponseEnvelope,
  SharedLessonPlanResponse,
  SupportedPublishers,
  UpdateLessonPlan, LessonPlanEvaluationResponse, LessonPlanEvaluationRequest
} from '@kurasa-monorepo/types';

@Injectable({
  providedIn: 'root',
})
export class LessonPlansApiService {
  private readonly baseApi = environment.api;

  constructor(
    private client: RestClientService,
    private mapErrorService: MapErrorService,
  ) {}

  create(
    request:
      | CreateCBCLessonPlanRequest
      | CreateIGCSELessonPlanRequest
      | CreateCCPLessonPlanRequest,
  ) {
    if (request.curriculum === 'ccp') {
      return this.client.post<
        | CreateCBCLessonPlanRequest
        | CreateIGCSELessonPlanRequest
        | CreateCCPLessonPlanRequest,
        ResponseEnvelope<any>
      >(`${this.baseApi}/create-lesson-plan/ccp`, request);
    }
    return this.client
      .post<
        | CreateCBCLessonPlanRequest
        | CreateIGCSELessonPlanRequest
        | CreateCCPLessonPlanRequest,
        ResponseEnvelope<any>
      >(`${this.baseApi}/create-lesson-plan`, request)
      .pipe(
        catchError((err) =>
          throwError(() => this.mapErrorService.mapError(err)),
        ),
      );
  }

  findOne(id: string) {
    return this.client
      .get<
        ResponseEnvelope<LessonPlanDetailsResponse>
      >(`${this.baseApi}/get-lesson-plan/${id}`)
      .pipe(map((response) => response.data));
  }

  update(request: UpdateLessonPlan) {
    return this.client.post(`${this.baseApi}/update-lesson-plan`, request);
  }

  remark(request: LessonPlanRemarkRequest) {
    return this.client.post(`${this.baseApi}/evaluate-lesson-plan`, request);
  }

  submitEvaluation(request: LessonPlanEvaluationRequest) {
    return this.client.post<LessonPlanEvaluationRequest, ResponseEnvelope<LessonPlanEvaluationResponse>>(`${this.baseApi}/lesson-plans/${request.lesson_plan_id}/evaluation`, request);
  }

  delete(lessonPlanId: string) {
    return this.client.post(`${this.baseApi}/delete-lesson-plan`, {
      lesson_plan_id: lessonPlanId,
    });
  }

  getRemarks(lessonPlanId: string) {
    return this.client
      .get<
        ResponseEnvelope<LessonPlanRemarksResponse>
      >(`${this.baseApi}/lesson-plans/${lessonPlanId}/remarks`)
      .pipe(map((response) => response.data));
  }

  getEvaluations(lessonPlanId: string) {
    return this.client
      .get<
        ResponseEnvelope<Array<LessonPlanEvaluationResponse>>
      >(`${this.baseApi}/lesson-plans/${lessonPlanId}/evaluations`)
      .pipe(map((response) => response.data));
  }

  deleteEvaluation(evaluationId: number) {
    return this.client.delete(`${this.baseApi}/lesson-plan-evaluation/${evaluationId}`);
  }


  listForUser(
    workspaceId: string,
    filter?: DateRange,
    learningArea?: LearningAreaAllocatedResponse | null,
  ) {
    if (learningArea) {
      return this.client
        .get<
          ResponseEnvelope<Array<LessonPlanItemResponse>>
        >(`${this.baseApi}/list-lesson-plans?workspace=${workspaceId}&cohorts[]=${learningArea.cohort_id}&learning_areas[]=${learningArea.subject_id}&start_date=${filter?.startDate}&end_date=${filter?.endDate}&all=1`)
        .pipe(map((response) => response.data));
    } else {
      return this.client
        .get<
          ResponseEnvelope<Array<LessonPlanItemResponse>>
        >(`${this.baseApi}/list-lesson-plans?workspace=${workspaceId}&start_date=${filter?.startDate}&end_date=${filter?.endDate}&all=1`)
        .pipe(map((response) => response.data));
    }
  }

  listAllWorkspaceLessonPlans(payload: {
    workspaceId: string;
    cohortId: string | 'all';
    subjectId: string | 'all';
    startDate: string;
    endDate: string;
  }) {
    let url = `${this.baseApi}/workspaces/${payload.workspaceId}/lesson-plans?start_date=${payload.startDate}&end_date=${payload.endDate}&all=1`;

    if (payload.cohortId !== 'all') {
      url += `&cohorts[]=${payload.cohortId}`;
    }

    if (payload.subjectId !== 'all') {
      url += `&learning_areas[]=${payload.subjectId}`;
    }
    return this.client.get<ResponseEnvelope<Array<LessonPlanItemResponse>>>(
      url,
    )
  }

  shareLessonPlan(lesson_plan_id: string, teacher_id: string) {
    return this.client.post(`${this.baseApi}/share-lesson-plan`, {
      lesson_plan_id,
      teacher_id,
    });
  }

  stopSharingPlan(shareId: string) {
    return this.client.delete(`${this.baseApi}/shared-lesson-plans/${shareId}`);
  }

  listSharedLessonPlans(
    paginationProps: PaginationRequest,
    academicYear?: number | null,
  ) {
    if (!academicYear) academicYear = new Date().getFullYear();
    return this.client.get<ResponseEnvelope<Array<SharedLessonPlanResponse>>>(
      `${this.baseApi}/lesson-plans-shared-out?page=${paginationProps.page}&limit=${paginationProps.limit}&academic_year=${academicYear}`,
    );
  }

  listReceivedLessonPlans(
    paginationProps: PaginationRequest,
    academicYear?: number | null,
  ) {
    if (!academicYear) academicYear = new Date().getFullYear();
    return this.client.get<ResponseEnvelope<Array<ReceivedLessonPlanResponse>>>(
      `${this.baseApi}/lesson-plans-received?page=${paginationProps.page}&limit=${paginationProps.limit}&academic_year=${academicYear}`,
    );
  }

  listTeachersToShareWith(workspaceId: string) {
    return this.client
      .get<
        ResponseEnvelope<Array<{ id: string; email: string; name: string }>>
      >(`${this.baseApi}/share-lesson-plan-teachers-list?workspace_id=${workspaceId}&all=1`)
      .pipe(map((response) => response.data));
  }

  listByCohort(workspaceId: string, cohortId: string, dateRange: DateRange) {
    return this.client
      .get<
        ResponseEnvelope<Array<LessonPlanItemResponse>>
      >(`${this.baseApi}/workspace-lesson-plans?workspace=${workspaceId}&cohorts[]=${cohortId}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&all=1`)
      .pipe(map((response) => response.data));
  }

  listLessonPlansFromSchemes(cohortId: string, substrandId: number) {
    return this.client
      .get<
        ResponseEnvelope<Array<LessonPlanFromSchemeResponse>>
      >(`${this.baseApi}/cohorts/${cohortId}/substrands/${substrandId}/lessons-from-schemes`)
      .pipe(map((response) => response.data));
  }

  listLessonPlansFromPublisher(
    publisherId: SupportedPublishers,
    substrandId: number,
  ) {
    return this.client
      .get<
        ResponseEnvelope<Array<PublisherLessonPlanResponse>>
      >(`${this.baseApi}/publishers/${publisherId}/substrands/${substrandId}/lesson-plans`)
      .pipe(map((response) => response.data));
  }

  listLessonPlansFromAI(substrandId: number) {
    return this.client
      .get<
        ResponseEnvelope<Array<AILessonPlan>>
      >(`${this.baseApi}/substrands/${substrandId}/preset-lesson-plans`)
      .pipe(map((response) => response.data));
  }
}
