import { Injectable } from '@angular/core';

import { RestClientService } from '@kurasa-monorepo/utils';
import { ResponseEnvelope } from '@kurasa-monorepo/types';
import { environment } from '@kurasa-monorepo/environments';

import { CommunityUser } from '../models/community-user';

@Injectable({
  providedIn: 'root',
})
export class CommunityUserApiService {
  private readonly BASE_URL = environment.api;

  constructor(private restClient: RestClientService) {}

  getStarted(phoneNumber: string, code: string | null) {
    return this.restClient.post<
      { phone_number: string; referral_code: string | null },
      ResponseEnvelope<CommunityUser>
    >(`${this.BASE_URL}/lp/get-started`, {
      phone_number: phoneNumber,
      referral_code: code,
    });
  }

  getReferralCode(phoneNumber: string) {
    return this.restClient.get<
      ResponseEnvelope<{
        referral_code: string;
      }>
    >(`${this.BASE_URL}/lp/teacher/referral-code?phone_number=${phoneNumber}`);
  }

  sendOtp(phoneNumber: string) {
    return this.restClient.post<
      { phone_number: string },
      ResponseEnvelope<null>
    >(`${this.BASE_URL}/send-otp`, {
      phone_number: phoneNumber,
    });
  }

  verifyOtp(phoneNumber: string, otp: number) {
    return this.restClient.post<
      { phone_number: string; otp: number },
      ResponseEnvelope<null>
    >(`${this.BASE_URL}/verify-otp`, {
      phone_number: phoneNumber,
      otp: otp,
    });
  }
}
