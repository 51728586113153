import { InjectionToken } from '@angular/core';

export class CurrentWorkspaceService {
  constructor() {}

  get workspaceId() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    return pathSegments[2];
  }
}

export const CURRENT_WORKSPACE_ID_TOKEN =
  new InjectionToken<CurrentWorkspaceService>(
    'Token to retrieve the current workspace in view',
    {
      providedIn: 'root',
      factory: () => new CurrentWorkspaceService(),
    },
  );
