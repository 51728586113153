import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { catchError, switchMap, tap, throwError } from 'rxjs';

import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';

import { KuiButtonComponent, KuiPillComponent } from '@kurasa-monorepo/ui';

import { CommunityUserStore } from '../../stores/community-user.store';
import { REFERRAL_CODE_QUERY_PARAM } from '../../models/guest-lesson-plans';

@Component({
  standalone: true,
  selector: 'get-started-modal',
  template: `
    <div [formGroup]="form" class="flex w-full flex-col gap-4">
      <div class="button-gradient flex w-full flex-col px-6 py-8">
        <span class="font-medium text-white"> Welcome to, </span>
        <span class="text-5xl font-black text-white">
          Kurasa <br />
          Community
        </span>
      </div>

      <div class="flex flex-col gap-4 p-6">
        <p>
          Unlock full access of all our Community features by verifying your
          account.
        </p>

        <ng-container *ngIf="view() === 'get-started'">
          <div class="flex flex-col space-y-2">
            <label
              id="phoneNumber"
              for="phoneNumber"
              class="text-sm font-semibold">
              Phone Number
            </label>
            <p-inputMask
              mask="(9999) 999-999"
              formControlName="phoneNumber"
              placeholder="(0712) 345-4567"
              styleClass="w-full h-[50px] rounded-md border border-gray-300" />
          </div>

          <div class="flex flex-col space-y-2">
            <label for="referralCode" class="text-sm font-semibold">
              Referral Code <span class="text-gray-500">(Optional)</span>
            </label>
            <input
              class="kui-input"
              id="referralCode"
              formControlName="referralCode"
              placeholder="e.g. 1234" />
          </div>

          <div *ngIf="getStartedError() !== null" class="w-full">
            <kui-pill [text]="getStartedError()!" type="error" />
          </div>

          <div class="flex justify-end">
            <kui-button
              [loading]="getStartedLoading()"
              [disabled]="form.invalid"
              (clicked)="onClick()"
              size="sm"
              label="Get Started" />
          </div>
        </ng-container>

        <ng-container *ngIf="view() === 'otp'">
          <div class="flex flex-col space-y-2">
            <label for="otp" class="text-sm font-semibold">
              Enter the OTP sent to {{ form.get('phoneNumber')?.value }}
            </label>
            <input
              type="text"
              id="otp"
              inputmode="numeric"
              autocomplete="one-time-code"
              class="kui-input w-full"
              [formControl]="otpControl" />

            <div *ngIf="otpError()" class="w-full">
              <kui-pill [text]="otpError()!" type="error" />
            </div>
          </div>

          <div class="flex flex-row justify-end space-x-4">
            <kui-button
              (clicked)="view.set('get-started')"
              size="sm"
              buttonType="white"
              label="Back" />
            <kui-button
              [loading]="otpLoading()"
              [disabled]="otpControl.invalid"
              (clicked)="onConfirmOtp()"
              size="sm"
              label="Confirm" />
          </div>
        </ng-container>
      </div>
    </div>
  `,
  imports: [
    FormsModule,
    InputMaskModule,
    ReactiveFormsModule,
    InputTextModule,
    NgIf,
    KuiPillComponent,
    KuiButtonComponent,
    InputNumberModule,
  ],
})
export class GetStartedModalComponent implements OnInit {
  private dialogRef = inject(DynamicDialogRef);
  public activatedRoute = inject(ActivatedRoute);
  public userStore = inject(CommunityUserStore);
  public destroyRef = inject(DestroyRef);

  public view = signal<'get-started' | 'otp'>('get-started');

  public getStartedError = signal<string | null>(null);

  public otpError = signal<string | null>(null);

  public form = new FormGroup({
    phoneNumber: new FormControl('', [Validators.required]),
    referralCode: new FormControl<string | null>(null),
  });

  public otpControl = new FormControl<number | null>(null, [
    Validators.required,
    Validators.min(4),
  ]);

  public getStartedLoading = signal(false);
  public otpLoading = signal(false);

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const code = queryParams[REFERRAL_CODE_QUERY_PARAM] ?? '';
    console.log('code', code);
    this.form.patchValue({
      referralCode: queryParams[REFERRAL_CODE_QUERY_PARAM] ?? '',
    });
  }

  onClick() {
    const formValue = this.form.value;
    const referralCode = formValue.referralCode ?? null;
    const phone = (formValue.phoneNumber as string)
      .trim()
      .replace(/[()\-\s]/g, '');
    this.getStartedLoading.set(true);
    this.getStartedError.set(null);
    this.userStore
      .getStarted(phone, referralCode)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((err) => {
          const status_message = err.error.status_message;
          if (status_message) {
            this.form.get('referralCode')?.setErrors({ invalid: true });
            this.getStartedError.set(status_message);
          } else {
            this.getStartedError.set(
              'An error occurred while trying to verify your account. Please try again.',
            );
          }
          this.getStartedLoading.set(false);
          return throwError(() => err);
        }),
        switchMap(() => {
          return this.userStore.sendOtp(phone);
        }),
        tap(() => {
          this.getStartedLoading.set(false);
          // this.view.set('otp');
          this.dialogRef.close();
        }),
      )
      .subscribe();
  }

  onConfirmOtp() {
    const otp = this.otpControl.value as number;
    const formValue = this.form.value;
    const phone = (formValue.phoneNumber as string)
      .trim()
      .replace(/[()\-\s]/g, '');
    this.otpError.set(null);
    this.otpLoading.set(true);
    this.userStore
      .verifyOtp(phone, otp)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((err) => {
          const status_message = err.error.status_message;
          if (status_message) {
            this.otpControl.setErrors({ invalid: true });
            this.otpError.set(status_message);
          } else {
            this.otpError.set(
              'An error occurred while trying to verify your account. Please try again.',
            );
          }
          this.otpLoading.set(false);
          return throwError(() => err);
        }),
        tap(() => {
          this.dialogRef.close();
        }),
      )
      .subscribe({
        next: () => {
          this.dialogRef.close();
        },
      });
  }
}
