import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { kuiPillStyles, KuiPillType } from './kui-pill-type';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'kui-pill',
  template: `
    <div
      [ngClass]="[
        classes,
        removable
          ? 'focus-within:text-warning hover:text-warning space-x-2 pl-3 pr-2 focus-within:border-red-200 focus-within:bg-red-50 focus-within:ring focus-within:ring-red-200 focus-within:ring-opacity-50 focus-within:ring-offset-2 hover:border-red-200 hover:bg-red-50'
          : 'px-4'
      ]"
      class="flex w-fit flex-row items-center rounded-full border py-1 text-sm">
      <span>{{ text }}</span>
      <ng-container *ngIf="removable">
        <button
          (click)="remove.emit()"
          class="flex items-center justify-center">
          <i class="pi pi-times-circle hover:text-warning text-sm"></i>
        </button>
      </ng-container>
    </div>
  `,
})
export class KuiPillComponent {
  @Input() text: string | number | null = 'Default text';
  @Input() type: KuiPillType = 'default';
  @Input() removable = false;
  @Output() remove = new EventEmitter();

  get classes() {
    return kuiPillStyles.get(this.type);
  }
}
