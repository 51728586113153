import { EnvironmentModel } from './env.model';

export const environment: EnvironmentModel = {
  production: true,
  stage: 'prod',
  version: '0.0.0',
  api: 'https://api.kurasa.co/api',
  tawkTo: {
    src: 'https://embed.tawk.to/6495421b94cf5d49dc5f5ee0/1h3jgkf5o',
    apiKey: '80964371a86cececf933a52bde6028e5a5823180',
  },
};
