import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, tap } from 'rxjs';

import { CommunityUser } from '../models/community-user';
import { CommunityUserApiService } from '../data-access/community-user-api.service';
import {
  CommunityLocalStorageKeys,
  LocalStorageService,
} from '../data-access/local-storage.service';
import { CreateGuestLessonPlanResponse } from '../models/guest-lesson-plans';

@Injectable({
  providedIn: 'root',
})
export class CommunityUserStore {
  private user = new BehaviorSubject<CommunityUser | null>(null);
  public user$ = this.user.asObservable();

  public isAuthenticated = toSignal(this.user$.pipe(map((user) => !!user)), {
    initialValue: false,
  });

  constructor(
    private communityUserApi: CommunityUserApiService,
    private localStoreService: LocalStorageService,
  ) {}


  getStarted(phoneNumber: string, code: string | null) {
    return this.communityUserApi.getStarted(phoneNumber, code).pipe(
      tap((response) => {
        this.user.next(response.data);
      }),
    );
  }

  listLessonPlans() {
    return this.localStoreService.get<Array<CreateGuestLessonPlanResponse>>(
      CommunityLocalStorageKeys.lesson_plans,
    );
  }

  update(user: Partial<CommunityUser>) {
    const currentUser = this.user.getValue();
    if (currentUser) {
      this.user.next({ ...currentUser, ...user });
    } else {
      this.user.next(user as CommunityUser);
    }
  }

  decrementCredits() {
    const currentUser = this.user.getValue();
    if (currentUser && currentUser.credits > 0) {
      const adjustedCredits = currentUser.credits - 1;
      this.user.next({ ...currentUser, credits: adjustedCredits });
    }
  }

  sendOtp(phoneNumber: string) {
    return this.communityUserApi.sendOtp(phoneNumber);
  }

  verifyOtp(phoneNumber: string, otp: number) {
    return this.communityUserApi.verifyOtp(phoneNumber, otp);
  }

  getUser() {
    return this.user.getValue();
  }
}
