export interface StudentAttendanceHistory {
  student_instance: string;
  student_id: string;
  fullname: string;
  attendance: Array<AttendanceModel>;
}

export interface AttendanceModel {
  day: string; // 2023-03-22
  day_no: string;
  comments: string | null;
  attendance: AttendanceType | null;
  attendance_name?: string;
}


export interface SingleStudentAttendanceModel {
  day: string; // 2023-03-22
  day_no: string;
  comments: string | null;
  attendance: AttendanceType | null;
  attendance_name?: string;
}

export interface MarkStudentAttendanceRequest {
  date: string; // 2023-03-22
  cohort: string;
  students_attendance: Array<{
    instance: string;
    attendance: AttendanceType;
    comments: string | null;
  }>;
}

export interface UpdateStudentAttendanceRequest {
  date: string; // 2023-03-22
  attendance: number;
  student_instance_id: string;
  cohort_id: string;
  comments: string | null;
}

export enum AttendanceType {
  FULL_DAY_PRESENT = 1,
  HALF_DAY_PRESENT = 2,
  ABSENT = 3,
}
