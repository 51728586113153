import { HttpErrorResponse } from '@angular/common/http';
import { ResponseEnvelope } from '@kurasa-monorepo/types';

import { Injectable } from '@angular/core';
import { injectNetwork } from 'ngxtension/inject-network';

@Injectable({
  providedIn: 'root',
})
export class MapErrorService {
  public networkState = injectNetwork();

  constructor() {}

  mapError(error: any): ResponseEnvelope<null> {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      // Check if the error is a network error
      if (this.networkState.online()) {
        return error.error as ResponseEnvelope<null>;
      } else {
        return {
          status_code: 400,
          status_message: 'Error',
          errors: [
            'No internet connection. Please check your network settings and try again.',
          ],
          data: null,
          pagination: null,
        };
      }
    } else if (error instanceof HttpErrorResponse && error.status >= 500) {
      return {
        status_code: 500,
        status_message: 'Server Error',
        errors: [
          "We're currently unable to complete your request. Please try again later. We appreciate your patience",
        ],
        data: null,
        pagination: null,
      };
    } else {
      return {
        status_code: 400,
        status_message: 'Error',
        errors: [
          'An error occurred while processing your request. Please try again later.',
        ],
        data: null,
        pagination: null,
      };
    }
  }
}
