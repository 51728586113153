import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';

import { KurasaFeatureNamesType } from '@kurasa-monorepo/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CurrentPlanStore } from '@kurasa-monorepo/data-access';

export const featureSupportResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
) => {
  const currentPlanStore = inject(CurrentPlanStore);
  const featureName = route.data['feature'] as KurasaFeatureNamesType;
  return !!currentPlanStore.getFeatureDetails(featureName);
};
