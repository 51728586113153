export interface LessonPlanDuration {
  duration: number;
  description: string;
}

export const LessonPlanDurations: Array<LessonPlanDuration> = [
  {
    duration: 25,
    description: '25 mins',
  },
  {
    duration: 30,
    description: '30 mins',
  },
  {
    duration: 35,
    description: '35 mins',
  },
  {
    duration: 40,
    description: '40 mins',
  },
  {
    duration: 45,
    description: '45 mins',
  },
  {
    duration: 60,
    description: '1 hour',
  },
  {
    duration: 70,
    description: '1 hour 10 mins',
  },
  {
    duration: 80,
    description: '1 hour 20 mins',
  },
  {
    duration: 90,
    description: '1 hour 30 mins',
  },
  {
    duration: 120,
    description: '2 hours',
  },
];
