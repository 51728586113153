import { Injectable } from '@angular/core';

export enum CommunityLocalStorageKeys {
  lesson_plans = 'lesson_plans',
  user = 'user',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public get<T>(key: CommunityLocalStorageKeys): T | null {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    return JSON.parse(item) as T;
  }

  public set<T>(key: CommunityLocalStorageKeys, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
