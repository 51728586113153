import { bootstrapApplication } from '@angular/platform-browser';

import { init } from '@sentry/angular-ivy';

import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

init({
  dsn: 'https://d54f45c92255ca8f9b7960332440814f@o1149787.ingest.us.sentry.io/4507140532535296',
  integrations: [],
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
