export type KuiPillType =
  | 'warning'
  | 'info'
  | 'success'
  | 'default'
  | 'purple'
  | 'error';

export const kuiPillStyles = new Map<KuiPillType, string>([
  ['info', 'bg-blue-50 text-blue-500 border-blue-200'],
  ['default', 'border-gray-200 bg-gray-50  text-gray-500'],
  ['warning', 'border-amber-200 bg-amber-50  text-amber-600'],
  ['error', 'border-red-200 bg-red-50  text-red-600'],
  ['purple', 'border-purple-200 bg-purple-50  text-purple-600'],
  ['success', 'border-green-200 bg-green-50  text-green-600'],
]);
