import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  KuiButtonSize,
  kuiButtonStyles,
  KuiButtonTheme,
  KuiButtonWidth,
} from './kui-button.type';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'kui-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      [disabled]="disabled || loading"
      (click)="onClick()"
      [ngClass]="[
        classes,
        width === 'full' ? 'w-full justify-center text-lg' : 'w-fit',
        size === 'md' ? 'py-3 text-base' : 'py-2 text-sm',
      ]"
      class="relative flex h-fit flex-row items-center space-x-4 rounded-md px-6 transition-all duration-300 ease-out focus:ring disabled:cursor-not-allowed disabled:opacity-70">
      <ng-container *ngIf="loading">
        <div
          class="absolute left-2 top-0 flex h-full w-fit items-center justify-center">
          <i
            [ngClass]="[size === 'md' ? 'text-base' : 'text-sm']"
            class="pi pi-spin pi-spinner"></i>
        </div>
      </ng-container>
      <div class="flex flex-row items-center gap-2">
        <i
          *ngIf="prefixIcon"
          [ngClass]="prefixIcon!"
          class="text-sm font-normal"></i>
        <span *ngIf="label" class="font-normal">
          {{ label }}
        </span>
        <i
          *ngIf="suffixIcon"
          [ngClass]="suffixIcon!"
          class="text-sm font-normal"></i>
      </div>
    </button>
  `,
})
export class KuiButtonComponent {
  @Input() buttonType: KuiButtonTheme = 'primary';
  @Input() width: KuiButtonWidth = 'fit';
  @Input() size: KuiButtonSize = 'md';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() label?: string;
  @Input() prefixIcon?: string;
  @Input() suffixIcon?: string;

  @Output() clicked = new EventEmitter<void>();

  onClick() {
    if (this.disabled || this.loading) return;
    this.clicked.emit();
  }

  get classes() {
    return kuiButtonStyles.get(this.buttonType);
  }
}
