import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RestClientService {
  constructor(public http: HttpClient) {}

  get<Response>(url: string): Observable<Response> {
    return this.http.get<Response>(url);
  }

  post<Request, Response>(
    url: string,
    payload?: Request,
  ): Observable<Response> {
    return this.http.post<Response>(url, payload);
  }

  put<Request, Response>(url: string, payload: Request): Observable<Response> {
    return this.http.put<Response>(url, payload);
  }

  patch<Request, Response>(
    url: string,
    payload?: Request,
  ): Observable<Response> {
    return this.http.patch<Response>(url, payload);
  }

  delete<Request, Response>(
    url: string,
    payload?: Request,
  ): Observable<Response> {
    return this.http.delete<Response>(url, {
      body: payload,
    });
  }
}
