export type KuiButtonTheme = 'primary' | 'secondary' | 'white' | 'danger';

export type KuiButtonWidth = 'fit' | 'full';

export type KuiButtonSize = 'sm' | 'md' | 'xs';

export const kuiButtonStyles = new Map<KuiButtonTheme, string>([
  [
    'primary',
    'bg-blue-600 text-white hover:bg-blue-500 disabled:bg-blue-500 disabled:text-gray-100 focus:ring-blue-500/50',
  ],
  [
    'secondary',
    'bg-slate-600 text-white hover:bg-slate-500 focus:ring-slate-500/50',
  ],
  [
    'white',
    'bg-white border border-gray-200 text-gray-500 hover:border-blue-300 hover:bg-blue-100 hover:text-primary-light focus:text-primary-light focus:ring-blue-500/50 focus:border-blue-300 focus:bg-blue-200',
  ],
  [
    'danger',
    'bg-white border border-red-200 text-red-500 hover:border-red-300 hover:bg-red-100 hover:text-warning focus:text-warning focus:ring-red-500/50 focus:border-red-300 focus:bg-red-200',
  ],
]);
