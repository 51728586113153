import { Component, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';
import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common';

import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { NgSelectConfig } from '@ng-select/ng-select';

import { ToastModule } from 'primeng/toast';
import { KuiButtonComponent } from '@kurasa-monorepo/ui';
import { SharedModule } from 'primeng/api';

import { CommunityUserStore } from './stores/community-user.store';
import { GetStartedModalComponent } from './components/get-started/get-started-modal.component';
import { CreditsModalComponent } from './components/credits/credits-modal.component';

@Component({
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    KuiButtonComponent,
    DynamicDialogModule,
    SharedModule,
    ToastModule,
    NgOptimizedImage,
    AsyncPipe,
    NgIf,
  ],
  selector: 'kurasa-monorepo-root',
  template: `
    <div class="flex min-h-screen w-full flex-col">
      <p-toast
        [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
        [baseZIndex]="5000"
        position="bottom-right">
        <ng-template let-message pTemplate="message">
          <div class="flex flex-1 flex-col">
            <span class="font-medium">{{ message.summary }}</span>
            <span class="text-xs">{{ message.detail }}</span>
          </div>
        </ng-template>
      </p-toast>
      <nav
        class="flex w-full flex-row items-center justify-between space-x-4 border-b border-amber-100 px-4 py-2 md:border-none">
        <a routerLink="/" class="flex flex-col items-center space-y-1">
          <div>
            <img
              ngSrc="/assets/kurasa-logo.png"
              width="76"
              height="35"
              alt="Kurasa Logo"
              class="object-cover object-center" />
          </div>
          <span
            class="text-accent text-xs font-medium uppercase leading-none tracking-wider">
            Community
          </span>
        </a>
        <div class="flex flex-row space-x-4">
          <ng-container *ngIf="user$ | async as user">
            <button
              (click)="onCreditsClick()"
              class="button-gradient focus:ring-accent/40 group relative flex w-fit flex-row items-center space-x-2 overflow-hidden rounded-full px-6 py-2 text-sm text-neutral-50 transition hover:contrast-125 focus:border-none focus:outline-none focus:ring-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
              </svg>
              <div class="flex flex-shrink-0 flex-row gap-2 text-white">
                <span class="hidden md:block">Credits</span>
                <span>{{ user.credits }}</span>
              </div>
              <div
                class="animate-shine-infinite absolute inset-0 -top-[20px] flex h-[calc(100%+40px)] w-full justify-center blur-[12px]">
                <div class="relative h-full w-8 bg-white/30"></div>
              </div>
            </button>
          </ng-container>
        </div>
      </nav>
      <main class="container mx-auto flex-1">
        <router-outlet></router-outlet>
      </main>
      <footer class="border-t border-gray-200 p-4 text-center">
        <p>Copyright © All rights reserved | Kurasa Africa</p>
      </footer>
    </div>
  `,
})
export class AppComponent implements OnDestroy {
  public dialogService = inject(DialogService);
  public activatedRoute = inject(ActivatedRoute);
  public userStore = inject(CommunityUserStore);
  private ngSelectConfig = inject(NgSelectConfig);
  public user$ = this.userStore.user$;

  private getStartedModalRef: DynamicDialogRef | undefined;
  public getStartedTimeout: ReturnType<typeof setTimeout> | undefined;

  private TIME = 50;

  private unsubscribe = new Subject();

  constructor() {
    this.ngSelectConfig.clearSearchOnAdd = true;
    this.showGetStartedModal();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public onCreditsClick() {
    this.dialogService.open(CreditsModalComponent, {
      styleClass: 'max-w-lg mx-2 rounded-lg xl:w-auto',
      contentStyle: {
        padding: '0',
      },
    });
  }

  private showGetStartedModal() {
    if (this.getStartedTimeout) clearTimeout(this.getStartedTimeout);
    this.getStartedTimeout = setTimeout(() => {
      if (this.getStartedModalRef) {
        this.getStartedModalRef.close();
      }
      this.getStartedModalRef = this.dialogService.open(
        GetStartedModalComponent,
        {
          styleClass: 'max-w-lg p-0 mx-2 overflow-hidden rounded-lg xl:w-auto',
          contentStyle: {
            padding: '0',
          },
          showHeader: false,
          closable: false,
        },
      );
    }, this.TIME);
  }
}
