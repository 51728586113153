import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';

import { environment } from '@kurasa-monorepo/environments';

import { REFERRAL_CODE_QUERY_PARAM } from '../../models/guest-lesson-plans';
import { CommunityUserApiService } from '../../data-access/community-user-api.service';
import { CommunityUserStore } from '../../stores/community-user.store';

@Component({
  selector: 'credits-modal',
  standalone: true,
  template: `
    <div class="flex w-full flex-col">
      <div
        class="button-gradient flex w-full flex-col items-center justify-center p-6 text-center">
        <div
          class="flex flex-row items-center gap-4 text-8xl font-black text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-16 w-16">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
          </svg>
          <span>
            {{ currentUser ? currentUser.credits : 0 }}
          </span>
        </div>

        <span class="text-white/50">Credits left</span>
      </div>

      <div class="flex flex-col gap-8 px-6 py-4">
        <span class="text-gray-500">
          Credits allow you to create lesson plans and download them later. You
          get more credits by referring friends and colleagues to Kurasa.
        </span>

        <div class="flex flex-col gap-2">
          <span class="text-gray-500">
            Use the link below to refer friends
          </span>

          <div class="flex flex-row items-center space-x-2">
            <input
              [formControl]="referralControl"
              class="kui-input w-full flex-1" />
            <button
              [disabled]="disableCta()"
              (click)="onAction()"
              class="flex w-fit flex-row items-center space-x-4 rounded-md bg-amber-500 px-6 py-2 text-sm font-medium text-white hover:contrast-150">
              <span>{{ ctaText() }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  imports: [InputTextModule, ReactiveFormsModule],
})
export class CreditsModalComponent implements OnInit {
  public userStore = inject(CommunityUserStore);
  public currentUser = this.userStore.getUser();
  public userApiService = inject(CommunityUserApiService);

  public ctaText = signal<CtaText>('Loading...');
  public disableCta = computed(
    () =>
      this.ctaText() === 'Loading...' ||
      this.ctaText() === 'Generating...' ||
      this.ctaText() === 'Copied',
  );

  public referralControl = new FormControl('');
  private copyTimeout: ReturnType<typeof setTimeout> | undefined;

  ngOnInit() {
    this.referralControl.disable();
    const host =
      environment.stage === 'prod'
        ? 'https://community.kurasa.co'
        : 'https://oup-kurasa.vercel.app';
    const user = this.userStore.getUser();
    if (user && user.phone) {
      this.userApiService.getReferralCode(user.phone).subscribe({
        next: (response) => {
          const referralLink = `${host}?${REFERRAL_CODE_QUERY_PARAM}=${response.data.referral_code}`;
          this.referralControl.setValue(referralLink);
          this.ctaText.set('Copy');
        },
      });
    }
  }

  onAction() {
    this.ctaText.set('Copied');
    navigator.clipboard.writeText(this.referralControl.value as string);
    if (this.copyTimeout) clearTimeout(this.copyTimeout);
    this.copyTimeout = setTimeout(() => {
      this.ctaText.set('Copy');
    }, 1000);
  }
}

type CtaText = 'Copy' | 'Copied' | 'Generating...' | 'Loading...';
