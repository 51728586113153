import { stringToEnum } from '../strToEnum';

export type KurasaPlanSlugType = 'community' | 'starter' | 'silver' | 'gold';

interface BasePlanResponse {
  id: number;
  slug: KurasaPlanSlugType;
  name: string;
  description: string;
  is_active: boolean;
  price: number;
  signup_fee: number;
  currency: string;
  trial_period: number;
  trial_interval: string;
  invoice_period: number;
  invoice_interval: string;
  grace_period: number;
  grace_interval: string;
  prorate_day: string | null;
  prorate_period: string | null;
  prorate_extend_due: string | null;
  active_subscribers_limit: number | null;
  sort_order: number;
  created_at: string;
  updated_at: string;
}

export interface KurasaPlan extends BasePlanResponse {
  features: Record<KurasaFeatureNamesType, KurasaFeatureDetails>;
}

const kurasaFeatureNames = [
  'learners_grade_book',
  'formative_assessments',
  'formative_pdf_reports',
  'lesson_planning_community',
  'lesson_planning_in_system',
  'guardians',
  'class_attendance',
  'reassessments',
  'scheme_of_work',
  'school_attendance',
  'summative_assessments',
] as const;

export const KurasaFeatureNamesEnum = stringToEnum<KurasaFeatureNamesType>([
  ...kurasaFeatureNames,
]);

export type KurasaFeatureNamesType = (typeof kurasaFeatureNames)[number];

export interface KurasaFeatureDetails {
  id: number;
  plan_id: number;
  slug: string;
  name: string;
  description: string;
  value: string;
  resettable_period: number;
  resettable_interval: string;
  sort_order: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
