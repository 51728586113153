import { Curriculum } from '../tiny';
import { FormControl } from '@angular/forms';

export interface LessonPlanItemResponse {
  id: string;
  cohort_id: string;
  cohort_name: string;
  strand: string;
  learning_area: string;
  subject_title: string;
  substrand: string;
  start: string;
  duration: number;
  color: string | null;
  start_string: string;
  allocation_name: string;
  created_at: string;
  updated_at: string | null;
  teacher_name: string;
  /*
  Indicates whether a subject teacher has left remarks on the lesson plan
  Only passed lesson plans can be evaluated, if null, it means the lesson plan has not been passed
  and therefore cannot be evaluated
  * */
  self_evaluated: boolean | null;
  /*
   * Indicates whether the lesson plan has evaluated by an admin
   * Only future lesson plans can be evaluated, if null, it means the lesson plan has not been evaluated
   * or the start date has passed
   **/
  evaluation_status: LessonPlanEvaluationStatus | null;
}

export interface LessonPlanPrefillOption {
  name: 'oup' | 'scheme' | 'ai';
  fields: Array<
    keyof CbcSubstrandForm | keyof CcpSubstrandForm | keyof IgcseSubstrandForm
  >;
}

export interface LessonPlanDetailsResponse {
  id: string;
  learning_area: number;
  cohort: string;
  cohort_name: string;
  level: number;
  subject_title: string;
  start: string;
  // ID of the strand
  strand: number;
  strand_name: string;
  // ID of the substrand
  substrand: number;
  substrand_name: string;
  content_standard?: number;
  content_standard_name?: string;
  lesson_number?: number;
  class_size?: number;
  indicator?: number;
  indicator_name?: string;
  curriculum: Curriculum;
  duration: number;
  created_at: string;
  updated_at: string | null;
  // ID of the teacher who created the lesson plan
  created_by: string;
  teacher_name: string;
  color: string;
  start_string: string;
  file: {
    // URL to download the lesson plan file
    url: string;
    // URL to view the lesson plan file
    route: string;
  };
  details:
    | CBCLessonPlanDetailsResponse
    | IGCSELessonPlanDetailsResponse
    | CCPLessonPlanDetailsResponse;
}

export interface CBCLessonPlanDetailsResponse {
  lesson_development: string;
  suggested_resources: Array<string>;
  specific_learning_outcomes: Array<string>;
  learning_experiences: Array<string>;
  competencies: Array<string>;
  community_service: Array<string>;
  key_inquiry_questions: Array<string>;
  parental_involvement: Array<string>;
  suggested_assessment: Array<string>;
  suggested_non_formal: Array<string>;
  homework: Array<string>;
  pcis: Array<string>;
  link_values: Array<string>;
  prefill_option: LessonPlanPrefillOption | null;
}

export interface CCPLessonPlanDetailsResponse {
  content_standard: number;
  content_standard_name: string;
  indicator: number;
  indicator_name: string;
  lesson_number: number;
  total_lessons: number;
  class_size: number;
  preferred_indicator_name: string;
  instructional_objectives: Array<string>;
  core_competencies: Array<string>;
  starter: string;
  starter_student_activities: Array<string>;
  starter_teacher_activities: Array<string>;
  new_learning: string;
  new_learning_student_activities: Array<string>;
  new_learning_teacher_activities: Array<string>;
  reflection: string;
  reflection_student_activities: Array<string>;
  reflection_teacher_activities: Array<string>;
  references: Array<string>;
  resources: Array<string>;
  keywords: Array<string>;
  evaluation: string;
  prefill_option: LessonPlanPrefillOption | null;
}

export interface IGCSELessonPlanDetailsResponse {
  lesson_development: string;
  suggested_resources: Array<string>;
  learning_objectives: Array<string>;
  suggested_references: Array<string>;
  rationale: string;
  prefill_option: LessonPlanPrefillOption | null;
}

interface BaseLessonPlanRequest {
  cohort: string;
  learning_area: number;
  strand: number;
  substrand: number;
  // In the format 2023-07-26 14:30
  start: string;
  duration: number;
  color: string;
  curriculum: Curriculum;
  prefill_option: LessonPlanPrefillOption | null;
}

export interface CreateCBCLessonPlanRequest extends BaseLessonPlanRequest {
  lesson_development: string;
  suggested_resources: Array<string>;
  specific_learning_outcomes: Array<string>;
  learning_experiences: Array<string>;
  competencies: Array<string>;
  community_service: Array<string>;
  key_inquiry_questions: Array<string>;
  parental_involvement: Array<string>;
  suggested_assessment: Array<string>;
  suggested_non_formal: Array<string>;
  homework: Array<string>;
  pcis: Array<string>;
  link_values: Array<string>;
}

export interface CreateIGCSELessonPlanRequest extends BaseLessonPlanRequest {
  lesson_development: string;
  suggested_resources: Array<string>;
  learning_objectives: Array<string>;
  suggested_references: Array<string>;
  rationale: string;
}

export interface CreateCCPLessonPlanRequest extends BaseLessonPlanRequest {
  class_size: number;
  lesson_number: number;
  total_lessons: number;
  content_standard: number;
  indicator: number;
  preferred_indicator_name: string;
  core_competencies: Array<string>;
  instructional_objectives: Array<string>;
  starter: string;
  starter_student_activities: Array<string>;
  starter_teacher_activities: Array<string>;
  new_learning: string;
  new_learning_student_activities: Array<string>;
  new_learning_teacher_activities: Array<string>;
  reflection: string;
  reflection_student_activities: Array<string>;
  reflection_teacher_activities: Array<string>;
  keywords: Array<string>;
  references: Array<string>;
  resources: Array<string>;
}

export type UpdateLessonPlan =
  | UpdateCBCLessonPlanRequest
  | UpdateIGCSELessonPlanRequest
  | UpdateCCPLessonPlanRequest;

interface UpdateCBCLessonPlanRequest {
  id: string;
  curriculum: Curriculum;
  duration: number;
  start: string;
  lesson_development: string;
  suggested_resources: Array<string>;
  specific_learning_outcomes: Array<string>;
  learning_experiences: Array<string>;
  competencies: Array<string>;
  community_service: Array<string>;
  key_inquiry_questions: Array<string>;
  parental_involvement: Array<string>;
  suggested_assessment: Array<string>;
  suggested_non_formal: Array<string>;
  homework: Array<string>;
  pcis: Array<string>;
  link_values: Array<string>;
  prefill_option: LessonPlanPrefillOption | null;
}

interface UpdateIGCSELessonPlanRequest {
  id: string;
  curriculum: Curriculum;
  duration: number;
  start: string;
  lesson_development: string;
  suggested_resources: Array<string>;
  learning_objectives: Array<string>;
  suggested_references: Array<string>;
  rationale: string;
  prefill_option: LessonPlanPrefillOption | null;
}

interface UpdateCCPLessonPlanRequest {
  id: string;
  curriculum: Curriculum;
  duration: number;
  start: string;
  preferred_indicator_name: string;
  core_competencies: Array<string>;
  instructional_objectives: Array<string>;
  starter: string;
  starter_student_activities: Array<string>;
  starter_teacher_activities: Array<string>;
  new_learning: string;
  new_learning_student_activities: Array<string>;
  new_learning_teacher_activities: Array<string>;
  reflection: string;
  reflection_student_activities: Array<string>;
  reflection_teacher_activities: Array<string>;
  keywords: Array<string>;
  resources: Array<string>;
  references: Array<string>;
  prefill_option: LessonPlanPrefillOption | null;
}

export interface LessonPlanSuggestionsResponse {
  id: string;
  name: string;
  strand_id: string;
  curriculum: Curriculum;
  lessons: string | null;
  index: string | null;
  objectives: Array<string>;
  learning_experiences: Array<string>;
  core_competencies: Array<string>;
  key_inquiry_questions: Array<string>;
  pci: Array<string>;
  other_subjects: Array<string>;
  community_service: Array<string>;
  values: Array<string>;
  learning_outcomes: Array<string>;
  suggested_resources: Array<string>;
  keywords: Array<string>;
  suggested_assessment: Array<string>;
  suggested_non_formal_activity: Array<string>;
  homework: Array<string>;
}

export interface UpdateLessonPlanRequest {
  id: string;
  start: string;
  duration: number;
  color: string;
  lesson_development: string;
  specific_learning_outcomes: Array<string>;
  learning_experiences: Array<string>;
  competencies: Array<string>;
  community_service: Array<string>;
  key_inquiry_questions: Array<string>;
  parental_involvement: Array<string>;
  suggested_resources: Array<string>;
  suggested_assessment: Array<string>;
  suggested_non_formal: Array<string>;
}

export interface SharedLessonPlanResponse {
  // id of the Shared instance
  id: string;
  // id of the lesson plan
  lesson_plan_id: string;
  cohort_name: string;
  subject_title: string;
  strand: string;
  substrand: string;
  shared_with: string;
}

export interface ReceivedLessonPlanResponse {
  // id of the Received instance
  id: string;
  // id of the lesson plan
  lesson_plan_id: string;
  cohort_name: string;
  subject_title: string;
  strand: string;
  substrand: string;
  shared_by: string;
}

export interface LessonPlanRemarkRequest {
  id: string;
  positive: string;
  negative: string;
  possible_improvement: string;
}

export interface LessonPlanRemarksResponse {
  positive: string;
  negative: string;
  possible_improvement: string;
  last_updated?: string;
}

export interface LessonPlanEvaluationRequest {
  lesson_plan_id: string;
  comment: string | null;
  status: LessonPlanEvaluationStatus;
}

export interface LessonPlanEvaluationResponse {
  id: number;
  lesson_plan_id: string;
  comment: string | null;
  status: LessonPlanEvaluationStatus;
  updated_at: string | null;
  created_at: string;
  evaluator: {
    id: string;
    first_name: string;
    last_name: string;
  };
}

export type LessonPlanEvaluationStatus =
  | 'pass'
  | 'fail'
  | 'pending'
  | 'unevaluated';

export interface LessonPlanFromSchemeResponse {
  id: string;
  lesson_number: number;
  week_number: number;
  term_number: number;
  academic_year: number;
  teacher: string;
  remarks: string | null;
  specific_learning_outcomes: Array<string>;
  key_inquiry_questions: Array<string>;
  learning_experiences: Array<string>;
  resources: Array<string>;
  assessments: Array<string>;
}

export interface PublisherLessonPlanResponse {
  id: number;
  substrand_lesson_no: number;
  lesson_development: string;
  suggested_assessment: Array<string>;
  suggested_resources: Array<string>;
}

export interface AILessonPlan {
  id: string;
  lesson_development: string;
  substrand_lesson_no: number;
  suggested_assessment: Array<string>;
  suggested_resources: Array<string>;
}

export enum SupportedPublishers {
  OUP = 1,
  LONGHORN,
}

export type CbcSubstrandForm = {
  learningExperiences: FormControl<Array<string> | null>;
  specificLearningOutcomes: FormControl<Array<string> | null>;
  keyInquiryQuestions: FormControl<Array<string> | null>;
  competencies: FormControl<Array<string> | null>;
  lessonDevelopment: FormControl<string | null>;
  communityService: FormControl<Array<string> | null>;
  parentalInvolvement: FormControl<Array<string> | null>;
  homework: FormControl<Array<string> | null>;
  suggestedResources: FormControl<Array<string> | null>;
  suggestedAssessment: FormControl<Array<string> | null>;
  suggestedNonFormal: FormControl<Array<string> | null>;
  linkValues: FormControl<Array<string> | null>;
  pcis: FormControl<Array<string> | null>;
};

export type IgcseSubstrandForm = {
  learningObjectives: FormControl<Array<string> | null>;
  suggestedReferences: FormControl<Array<string> | null>;
  rationale: FormControl<string | null>;
  suggestedResources: FormControl<Array<string> | null>;
  lessonDevelopment: FormControl<string | null>;
};

export type CcpSubstrandForm = {
  preferredIndicatorName: FormControl<string | null>;
  instructionalObjectives: FormControl<Array<string> | null>;
  coreCompetencies: FormControl<Array<string> | null>;
  starter: FormControl<string | null>;
  starterStudentActivities: FormControl<Array<string> | null>;
  starterTeacherActivities: FormControl<Array<string> | null>;
  newLearning: FormControl<string | null>;
  newLearningStudentActivities: FormControl<Array<string> | null>;
  newLearningTeacherActivities: FormControl<Array<string> | null>;
  reflection: FormControl<string | null>;
  reflectionStudentActivities: FormControl<Array<string> | null>;
  reflectionTeacherActivities: FormControl<Array<string> | null>;
  keywords: FormControl<Array<string> | null>;
  references: FormControl<Array<string> | null>;
  resources: FormControl<Array<string> | null>;
};
